import React, { Fragment, useContext } from "react";
import {
  Button,
  Card,
  Collapse,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import CollapseHeader from "../../shared/CollapseHeader";
import {
  convertToCurrency,
  convertToDuplicateStackRatePlanNames,
  convertToOneTimePackageDetails,
  convertToRecurringDiscountDetails,
  momentStartOfDay,
} from "../../../utility/util";
import { AccountContext } from "../../../context/AccountProvider";
import {
  USER_PAYROLL_STATUS,
  UserContext,
} from "../../../context/UserProvider";
import { SubscriptionPreviewResponse } from "../../../utility/member-portal-api-types";
import { useConfig } from "../../../configuration/useConfig";
import { differenceInMonths, parseISO, addDays } from "date-fns";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImmediatePaymentPackages } from "../../../context/SummaryProvider";
import useSummary from "../../../hooks/useSummary";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
type CouponInfo = {
  codeName: string;
  description: string;
  updatedAmount: number;
};
const getPromocodeModalInfo = (
  summary: SubscriptionPreviewResponse
): CouponInfo => {
  let codeName = "";
  if (summary.monthlyPaymentSummary?.detailedPromoCodeDescription) {
    const unusedPromoCodesFound =
      summary.monthlyPaymentSummary?.detailedPromoCodeDescription.find(
        (promos) => !promos.isSystemapplied
      );
    if (unusedPromoCodesFound) {
      codeName = unusedPromoCodesFound.promoCodeUsed || "";
    }
  }

  let monthsOfDiscount = 0;
  let amountDiscounted = 0;
  const hasDiscountsOnPaymentSummary =
    summary.monthlyPaymentSummary?.monthlyPaymentDetails?.some(
      (details) => details.chargeType === "DISCOUNT"
    );
  if (hasDiscountsOnPaymentSummary) {
    const latestDiscount =
      summary.monthlyPaymentSummary?.monthlyPaymentDetails?.filter(
        (details) => details.chargeType === "DISCOUNT"
      )[0];
    amountDiscounted = latestDiscount?.amountWithoutTax || 0;

    monthsOfDiscount = differenceInMonths(
      addDays(parseISO(latestDiscount?.serviceEndDate || ""), 1), // Adding 1 day so that it is 30 days instead of 29 days
      parseISO(latestDiscount?.serviceStartDate || "")
    );
  }

  const description = `$${Math.abs(
    amountDiscounted
  )} Off ${monthsOfDiscount} Months`;

  const updatedAmount =
    summary.monthlyPaymentSummary
      ?.monthlyTotalPaymentAfterDiscountAmountWithoutTax ||
    summary.monthlyPaymentSummary?.monthlyTotalPaymentAmountWithoutTax ||
    0;
  return {
    codeName,
    description,
    updatedAmount,
  };
};

const PromoCodeModal = ({
  couponSummary,
  onHide,
  show,
  applyCoupon,
  onCancelPromo,
}: {
  couponSummary: CouponInfo;
  onHide: () => void;
  onCancelPromo: () => void;
  show: boolean;
  applyCoupon: () => void;
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <Modal.Title>
          <h2 style={{ marginBottom: 40 }}>
            Promo code '{couponSummary.codeName}' was found
          </h2>
        </Modal.Title>
        <p>
          This promo code applies a{" "}
          <span style={{ fontWeight: "bold" }}>
            {couponSummary.description}
          </span>{" "}
          to your account. Your next bill will be reduced to{" "}
          <span style={{ fontWeight: "bold" }}>
            ${Math.abs(couponSummary.updatedAmount)}
          </span>
          . Would you like to apply this code to your account?
        </p>
        <p style={{ fontStyle: "italic", fontSize: ".9rem", marginTop: 10 }}>
          Please note: this promo code can not be combined with subsequent codes
          during the time it has applied.
        </p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Button
          style={{ width: "40%", height: "40%", fontSize: ".9rem" }}
          className="font-weight-bold"
          variant="outline-primary"
          onClick={onCancelPromo}
        >
          No, Discard Promo Code
        </Button>
        <Button
          style={{ width: "40%", height: "40%", fontSize: ".9rem" }}
          className="font-weight-bold"
          variant="primary"
          onClick={applyCoupon}
        >
          Yes, Apply Promo Code
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PlanSummary = ({
  //   summary,
  //   currentPlanName,
  previewData,
  userTermed = false,
  loadingPending,
  promoLoading,
  errorSummary,
  isUpgrade,
  couponLoading,
  onApplyPromo,
  onPromoSubmit,
  onPreviewPromoSubmit,
  onPromoChange,
  onCancelPromo,
  showPromoModal,
  onClosePromoModal,
  validPromo,
  currentPromo,
  promo,
  isChange,
}: {
  //   summary: SummaryUIMap | null;
  previewData: SubscriptionPreviewResponse;
  currentPlanTotal: string;
  userTermed?: boolean;
  loadingPending: boolean;
  couponLoading?: boolean;
  promoLoading: boolean;
  errorSummary: boolean;
  validPromo: string;
  promoOpen: boolean;
  promo: string;
  currentPromo: string;
  onPromoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPromoRemove: (name: string) => void;
  onPromoSubmit: () => void;
  onPreviewPromoSubmit?: () => void;
  onApplyPromo?: () => void;
  onCancelPromo?: () => void;
  showPromoModal?: boolean;
  onClosePromoModal?: () => void;
  isUpgrade?: boolean;
  isChange: boolean;
}) => {
  const { nextPayment, member, payrollStatus } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { config, clientConfig } = useConfig();
  const { promoCodeError } = useSummary();
  const { isSmallScreen } = useGetScreenSize();

  const currentPlanName = member?.eligibilities
    ? member?.eligibilities[0].currentSubscription?.isLegacyProduct
      ? "Legacy Power"
      : member?.eligibilities[0].currentSubscription?.ratePlanName
    : "";

  const currentPlanDetails = member?.subscriptionAccount?.currentPlanDetails;
  let convertedCurrentPlanDetails;
  if (currentPlanDetails?.currentPlans) {
    convertedCurrentPlanDetails = convertToDuplicateStackRatePlanNames(
      currentPlanDetails?.currentPlans
    );
  } else {
    convertedCurrentPlanDetails = currentPlanDetails?.currentPlans;
  }
  let amount: string | undefined;
  const due = momentStartOfDay(nextPayment?.due).format("MM/DD/YYYY");

  const convertDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const adjustedDate = new Date(date);
    adjustedDate.setUTCHours(0, 0, 0, 0);
    const month = monthNames[adjustedDate.getUTCMonth()];
    const day = adjustedDate.getUTCDate();
    const result = month + " " + day;
    return result;
  };

  // Authenticated member with an active subscription
  if (nextPayment!.amount > 0 && nextPayment!.due.length > 0) {
    amount = nextPayment?.amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  return (
    <Fragment>
      <Card bg={`${loadingPending && "light"}`} className="edit-plan__summary">
        <Card.Body>
          <Card.Title>Current Plan</Card.Title>
          {!userTermed ? (
            <>
              {clientConfig.isDependentAccessLinked &&
              (payrollStatus === USER_PAYROLL_STATUS.Payroll ||
                payrollStatus === USER_PAYROLL_STATUS.NonPayroll) ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <span
                    className="edit-plan__text"
                    style={{ fontSize: "1rem" }}
                  >
                    <i>{currentPlanName}</i>
                  </span>
                  <span
                    className="edit-plan__text"
                    style={{ fontSize: "1rem" }}
                  >
                    {convertToCurrency(
                      member?.eligibilities[0]?.currentSubscription?.price
                    )}
                  </span>
                </div>
              ) : !clientConfig.isDependentAccessLinked &&
                payrollStatus === USER_PAYROLL_STATUS.NotApplicable ? (
                convertedCurrentPlanDetails?.map((plan) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <span
                      className="edit-plan__text"
                      style={{ fontSize: "1rem" }}
                    >
                      <i>
                        {plan.quantity ? `(${plan.quantity}) ` : ""}
                        {plan.ratePlanName ===
                        "Well OnTarget Fitness Program - Grandfathered Rate"
                          ? "Legacy Power"
                          : plan.ratePlanName}{" "}
                        Package
                      </i>
                    </span>
                    <span
                      className="edit-plan__text"
                      style={{ fontSize: "1rem" }}
                    >
                      {convertToCurrency(plan.price)}
                    </span>
                  </div>
                ))
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className="edit-plan__text" style={{ fontSize: "1rem" }}>
                  <b>{!user.isPayroll ? `Monthly Total` : `Per Paycheck`}</b>
                </span>
                <span
                  className="edit-plan__text"
                  style={{ fontWeight: "bolder", fontSize: "1rem" }}
                >
                  {convertToCurrency(
                    payrollStatus === USER_PAYROLL_STATUS.Payroll ||
                      payrollStatus === USER_PAYROLL_STATUS.NonPayroll
                      ? member?.eligibilities[0].currentSubscription?.price
                      : currentPlanDetails?.monthlyTotalAmount
                      ? parseFloat(currentPlanDetails?.monthlyTotalAmount)
                      : parseInt("0.00")
                  )}
                </span>
              </div>
              <span
                className="edit-plan__taxes_disclaimer"
                style={{ paddingTop: "1rem" }}
              >
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                <b>
                  {" "}
                  Does not include applicable taxes{" "}
                  {config["client"] !== "walmart" ? `and discounts` : ""}
                </b>
              </span>
            </>
          ) : (
            <div>
              <h6>
                <i>
                  <strong>No current package</strong>
                </i>
              </h6>
            </div>
          )}
        </Card.Body>
        {/* {!summary?.pending && !loadingPending && isChange && ( */}
        {!loadingPending && !previewData.success && (
          <Card.Footer className="summary-container__footer">
            {!nextPayment?.amount
              ? "No payment due"
              : `Your next payment of ${amount} is due on ${due}`}
          </Card.Footer>
        )}
        <Collapse in={errorSummary} unmountOnExit mountOnEnter>
          <div className="summary-container__error">
            <h4>
              <b>Sorry, there was an error.</b>
            </h4>
          </div>
        </Collapse>
        <Collapse
          in={loadingPending && !promoLoading && !errorSummary}
          mountOnEnter
        >
          <div className="summary-container__loading">
            <h4>Updating your Order Summary</h4>
            <Spinner animation="border" />
          </div>
        </Collapse>
        {previewData.success && !loadingPending && !errorSummary && (
          <Collapse in={!loadingPending}>
            <div>
              <Card className="card-no-rounded-border card-no-rounded-border__new-plan">
                <Card.Body>
                  <Card.Title>New Plan</Card.Title>
                  <CollapseHeader
                    header="Package Selection(s) Total"
                    price={
                      previewData.monthlyPaymentSummary
                        ?.monthlyTotalPaymentAmountWithoutTax
                        ? convertToCurrency(
                            previewData.monthlyPaymentSummary
                              ?.monthlyTotalPaymentAmountWithoutTax
                          )
                        : convertToCurrency(parseInt("0.00"))
                    }
                  >
                    <>
                      {convertToOneTimePackageDetails(
                        (previewData.monthlyPaymentSummary
                          ?.monthlyPaymentDetails as ImmediatePaymentPackages[]) ??
                          []
                      )?.map((p) => (
                        <p className="mb-1">
                          <i>
                            {p.quantity !== undefined && p.quantity > 1
                              ? `(${p.quantity}) ${p.name}`
                              : p.name}{" "}
                            Package
                          </i>
                          {p.subtotal ? (
                            <span style={{ float: "right" }}>
                              {convertToCurrency(p.subtotal)}
                            </span>
                          ) : null}
                        </p>
                      ))}
                    </>
                  </CollapseHeader>
                  <CollapseHeader
                    header="Discount(s) Total"
                    price={
                      previewData.monthlyPaymentSummary
                        ?.monthlyPaymentDiscountAmountWithoutTax
                        ? convertToCurrency(
                            previewData.monthlyPaymentSummary
                              ?.monthlyPaymentDiscountAmountWithoutTax
                          )
                        : convertToCurrency(parseInt("0.00"))
                    }
                  >
                    <>
                      {convertToRecurringDiscountDetails(
                        previewData.monthlyPaymentSummary
                          ?.monthlyPaymentDetails ?? []
                      )?.map((p) => (
                        <p className="mb-1">
                          <i>
                            {p.quantity !== undefined && p.quantity > 1
                              ? `(${p.quantity}) ${p.name}`
                              : p.name}
                          </i>
                          {p.subtotal ? (
                            <span style={{ float: "right" }}>
                              {convertToCurrency(p.subtotal)}
                            </span>
                          ) : null}
                        </p>
                      ))}
                    </>
                  </CollapseHeader>
                  {/* {summary.pending?.showDiscounts && (
                  <CollapseHeader
                    header="Discount(s) Total"
                    price={summary.pending.discountsTotal}
                  >
                    {formatContentArray(summary.pending.discounts)}
                  </CollapseHeader>
                )} */}
                  {!user.isPayroll ? (
                    <p className="mt-3 mb-1">
                      Taxes
                      <span style={{ float: "right" }}>
                        {previewData.monthlyPaymentSummary
                          ?.monthlyTotalPaymentTaxAmount
                          ? convertToCurrency(
                              previewData.monthlyPaymentSummary
                                ?.monthlyTotalPaymentTaxAmount
                            )
                          : convertToCurrency(parseInt("0.00"))}
                      </span>
                    </p>
                  ) : null}
                  <p className="mb-0">
                    <b>
                      {!user.isPayroll
                        ? `New Monthly Total`
                        : `New Per Paycheck`}
                      <span style={{ float: "right" }}>
                        {previewData.monthlyPaymentSummary
                          ?.monthlyTotalPaymentAfterDiscountAmountWithTax
                          ? convertToCurrency(
                              previewData.monthlyPaymentSummary
                                ?.monthlyTotalPaymentAfterDiscountAmountWithTax
                            )
                          : convertToCurrency(parseInt("0.00"))}
                      </span>
                    </b>
                  </p>
                  <span>
                    <small>
                      <i>
                        Starting{" "}
                        {convertDate(
                          isUpgrade
                            ? previewData.immediatePaymentSummary
                                ?.immediatePaymentDetails?.[0]?.serviceStartDate
                            : previewData.monthlyPaymentSummary
                                ?.monthlyPaymentDetails?.[0]?.serviceStartDate
                        )}
                      </i>
                    </small>
                  </span>
                </Card.Body>
              </Card>
              {previewData && !errorSummary && !user.isPayroll && isUpgrade && (
                <>
                  <Card className="card-no-rounded-border card-no-rounded-border__due-now">
                    <Card.Body>
                      <Card.Title>Due Now</Card.Title>
                      <CollapseHeader
                        header="Package Selection(s) Total"
                        price={
                          previewData.immediatePaymentSummary
                            ?.immediateTotalPaymentRecurringFee
                            ? convertToCurrency(
                                previewData.immediatePaymentSummary
                                  ?.immediateTotalPaymentRecurringFee
                              )
                            : convertToCurrency(parseInt("0.00"))
                        }
                      >
                        <>
                          {convertToOneTimePackageDetails(
                            (previewData.immediatePaymentSummary
                              ?.immediatePaymentDetails as ImmediatePaymentPackages[]) ??
                              []
                          )?.map((p) => (
                            <p className="mb-1">
                              <i>
                                {p.quantity !== undefined && p.quantity >= 1
                                  ? `(${p.quantity}) ${p.name}`
                                  : p.name}{" "}
                                Package
                              </i>
                              {p.subtotal ? (
                                <span style={{ float: "right" }}>
                                  {convertToCurrency(p.subtotal)}
                                </span>
                              ) : null}
                            </p>
                          ))}
                        </>
                      </CollapseHeader>
                      <CollapseHeader
                        header="Enrollment Fees"
                        price={
                          previewData.immediatePaymentSummary
                            ?.immediateTotalPaymentSetUpFee
                            ? convertToCurrency(
                                previewData.immediatePaymentSummary
                                  ?.immediateTotalPaymentSetUpFee
                              )
                            : convertToCurrency(parseInt("0.00"))
                        }
                      />
                      <CollapseHeader
                        header="Discount(s) Total"
                        price={
                          previewData.immediatePaymentSummary
                            ?.immediatePaymentDiscountAmountWithoutTax
                            ? convertToCurrency(
                                previewData.immediatePaymentSummary
                                  ?.immediatePaymentDiscountAmountWithoutTax
                              )
                            : convertToCurrency(parseInt("0.00"))
                        }
                      >
                        <>
                          {convertToRecurringDiscountDetails(
                            previewData.immediatePaymentSummary
                              ?.immediatePaymentDetails ?? []
                          )?.map((p) => (
                            <p className="mb-1">
                              <i>
                                {p.quantity !== undefined && p.quantity > 1
                                  ? `(${p.quantity}) ${p.name}`
                                  : p.name}
                              </i>
                              {p.subtotal ? (
                                <span style={{ float: "right" }}>
                                  {convertToCurrency(p.subtotal)}
                                </span>
                              ) : null}
                            </p>
                          ))}
                        </>
                      </CollapseHeader>
                      {!user.isPayroll && isUpgrade ? (
                        <>
                          <p className="mb-1">
                            <b>
                              Prorated Amount
                              <span style={{ float: "right" }}>
                                {previewData.immediatePaymentSummary
                                  ?.creditAmountWithTax
                                  ? convertToCurrency(
                                      previewData.immediatePaymentSummary
                                        ?.creditAmountWithTax
                                    )
                                  : convertToCurrency(parseInt("0.00"))}
                              </span>
                            </b>
                          </p>
                        </>
                      ) : null}
                      {clientConfig.isPromoCodeAllowed ? (
                        <div className="enrollment-container">
                          <p className="font-weight-bold text-uppercase text-secondary mb-0 small">
                            Have a Promo Code?
                          </p>
                          {!isSmallScreen ? (
                            <>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Code Here"
                                  aria-label="Recipient's username"
                                  aria-describedby="button-addon2"
                                  onChange={onPromoChange}
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    id="button-addon2"
                                    onClick={
                                      couponLoading
                                        ? () => null
                                        : onPreviewPromoSubmit
                                    }
                                    disabled={
                                      couponLoading ||
                                      (validPromo === "FALSE" &&
                                        currentPromo === promo)
                                    }
                                  >
                                    {couponLoading ? "Loading..." : "Apply"}
                                  </button>
                                </div>
                              </div>
                              <div>
                                {promoCodeError && (
                                  <p
                                    className="summary-container_promo-code-error"
                                    style={{
                                      marginTop: "-0.75rem",
                                      paddingBottom: "0.5rem",
                                    }}
                                  >
                                    {promoCodeError}
                                  </p>
                                )}
                              </div>
                            </>
                          ) : (
                            <div style={{ marginBottom: "20px" }}>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Code Here"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                onChange={onPromoChange}
                              />
                              {promoCodeError && (
                                <p
                                  className="summary-container_promo-code-error"
                                  style={{
                                    marginTop: "0.25rem",
                                    paddingBottom: "0.25rem",
                                  }}
                                >
                                  {promoCodeError}
                                </p>
                              )}
                              <button
                                className="btn btn-primary mt-1"
                                type="button"
                                id="button-addon2"
                                onClick={
                                  couponLoading
                                    ? () => null
                                    : onPreviewPromoSubmit
                                }
                                disabled={
                                  couponLoading ||
                                  (validPromo === "FALSE" &&
                                    currentPromo === promo)
                                }
                              >
                                {couponLoading ? "Loading..." : "Apply"}
                              </button>
                            </div>
                          )}
                        </div>
                      ) : null}
                      <p className="mt-3 mb-1">
                        Subtotal
                        <span style={{ float: "right" }}>
                          {previewData.immediatePaymentSummary
                            ?.immediateTotalPaymentAfterDiscountAmountWithoutTax
                            ? convertToCurrency(
                                previewData.immediatePaymentSummary
                                  ?.immediateTotalPaymentAfterDiscountAmountWithoutTax
                              )
                            : convertToCurrency(parseInt("0.00"))}
                        </span>
                      </p>
                      <p className="mb-1">
                        Taxes
                        <span style={{ float: "right" }}>
                          {previewData.immediatePaymentSummary
                            ?.immediateTotalPaymenAfterDiscountTaxAmount
                            ? convertToCurrency(
                                previewData.immediatePaymentSummary
                                  ?.immediateTotalPaymenAfterDiscountTaxAmount
                              )
                            : convertToCurrency(parseInt("0.00"))}
                        </span>
                      </p>
                      <p className="mb-1">
                        <b>
                          Total Due Now
                          <span style={{ float: "right" }}>
                            {previewData.immediatePaymentSummary
                              ?.immediateTotalPaymentAfterDiscountAmountWithTax
                              ? convertToCurrency(
                                  previewData.immediatePaymentSummary
                                    ?.immediateTotalPaymentAfterDiscountAmountWithTax
                                )
                              : convertToCurrency(parseInt("0.00"))}
                          </span>
                        </b>
                      </p>
                    </Card.Body>
                  </Card>
                  {/* <Card bg="light">
                  <Card.Body>
                    <div
                      className="plan-summary-promo-code"
                      onClick={handleTogglePromoCode}
                    >
                      <FontAwesomeIcon icon={faTags} />
                      <span>Enter Promo Code</span>
                    </div>
                    {/* </Card.Body>
                </Card> 
                    {isPromoCode && (
                      <div style={{ margin: "1rem 0" }}>
                        <CollapseHeader
                          header="Discount(s) Total"
                          price={
                            summary.due?.discountsTotal
                              ? convertToCurrency(
                                  parseFloat(summary!.due!.discountsTotal)
                                )
                              : convertToCurrency(parseInt("0.00"))
                          }
                          initial={true}
                        >
                          {promoLoading ? (
                            <div
                              className="center-loading"
                              style={{ marginTop: "0" }}
                            >
                              <Spinner animation="border" />
                            </div>
                          ) : (
                            <>
                              {formatContentArray(summary.due?.discounts)}
                              {summary?.due.promoCodes?.map((code) => {
                                if (code.status === "SUCCESS") {
                                  return (
                                    <PromoCodeDisplay
                                      name={code.code}
                                      description={code.description}
                                      onRemove={onPromoRemove}
                                    />
                                  );
                                }
                              })}
                              <PromoCollapseHeader
                                header="Add Promo Code"
                                promoState={promoOpen}
                              >
                                <>
                                  <Form.Label className="mt-3 mr-2">
                                    Enter Promotional Code
                                  </Form.Label>
                                  {summary?.due.promoCodes?.map((code) => {
                                    if (
                                      code.code === promo &&
                                      code.status === "SUCCESS" &&
                                      currentPromo !== ""
                                    ) {
                                      return (
                                        <p className="text-danger">
                                          This promo code has already been
                                          applied
                                        </p>
                                      );
                                    }
                                  })}
                                  <InputGroup>
                                    <FormControl
                                      type="text"
                                      placeholder="Enter Code Here"
                                      onChange={onPromoChange}
                                      isInvalid={
                                        validPromo === "FALSE" &&
                                        promo !== "" &&
                                        currentPromo === promo
                                      }
                                    ></FormControl>
                                    <InputGroup.Append>
                                      <InputGroup.Append>
                                        <Button
                                          variant="primary"
                                          onClick={onPromoSubmit}
                                          disabled={
                                            validPromo === "FALSE" &&
                                            currentPromo === promo
                                          }
                                        >
                                          Apply
                                        </Button>
                                      </InputGroup.Append>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">
                                      Invalid Promo Code
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </>
                              </PromoCollapseHeader>
                            </>
                          )}
                        </CollapseHeader>
                      </div>
                    )}
                  </Card.Body>
                </Card> 
            */}
                </>
              )}
            </div>
          </Collapse>
        )}
      </Card>
      {clientConfig.isPromoCodeAllowed && !isChange && !loadingPending && (
        <Card className="card-promo-code">
          <Card.Body>
            <Card.Text className="card-promo-code__header">
              Have a Promo Code?
            </Card.Text>
            {/* {summary?.due.promoCodes?.map((code) => {
            if (
              code.code === promo &&
              code.status === "SUCCESS" &&
              currentPromo !== ""
            ) {
              return (
                <p className="text-danger">
                  This promo code has already been applied
                </p>
              );
            }
          })} */}
            <InputGroup>
              <FormControl
                type="text"
                placeholder="Enter promo code..."
                onChange={onPromoChange}
                isInvalid={
                  validPromo === "FALSE" &&
                  promo !== "" &&
                  currentPromo === promo
                }
              ></FormControl>
              <InputGroup.Append>
                <InputGroup.Append>
                  <Button
                    variant="primary"
                    className="card-promo-code__button"
                    onClick={couponLoading ? () => null : onPromoSubmit}
                    disabled={
                      couponLoading ||
                      (validPromo === "FALSE" && currentPromo === promo)
                    }
                  >
                    {couponLoading ? "Loading..." : "Apply"}
                  </Button>
                </InputGroup.Append>
              </InputGroup.Append>
              <Form.Control.Feedback type="invalid">
                Invalid Promo Code
              </Form.Control.Feedback>
            </InputGroup>
          </Card.Body>
        </Card>
      )}
      {onClosePromoModal && onCancelPromo && onApplyPromo && (
        <PromoCodeModal
          show={Boolean(showPromoModal)}
          onHide={onClosePromoModal}
          applyCoupon={onApplyPromo}
          onCancelPromo={onCancelPromo}
          couponSummary={getPromocodeModalInfo(previewData)}
        />
      )}
    </Fragment>
  );
};

export default PlanSummary;
