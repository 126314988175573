import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GeneralRouting from "./routing/GeneralRouting";
import Wizard from "./components/wizard/Wizard";
import "./styles/main.scss";
import AccountRouting from "./routing/AccountRouting";
import AuthWrapper from "./components/shared/AuthWrapper";
import { useContext, useEffect, useState } from "react";
import Terms from "./pages/Terms";
import FitnessProgramAgreement from "./pages/FitnessProgramAgreement";
import Locations from "./pages/Locations";
import AccountProvider from "./context/AccountProvider";
import Impersonate from "./pages/Impersonate";
import CsrBanner from "./components/shared/CsrBanner";
import { ImpersonationContext } from "./context/ImpersonationProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import AccountRequiredWrapper from "./components/shared/AccountRequiredWrapper";
import ProductBundleProvider from "./context/ProductBundleProvider";
import { hotjar } from "react-hotjar";
import Maintenance from "./pages/Maintenance";
import Privacy from "./pages/Privacy";
import { defaultConfig, dynamicConfigUrl } from "./configuration/config";
import { ConfigContextObject, useConfig } from "./configuration/useConfig";
import Boot from "./Boot";
import SSO from "./pages/SSO";
import axiosInstance, {
  axiosMemberInstance,
  axiosMemberCardInstance,
  axiosNetworkInstance,
  axiosBaseInstance,
} from "./axios.instance";
import Success from "./pages/Success";
import { MockPanel } from "./components/mockpanel/MockPanel";
import useAxiosMock from "./hooks/useAxiosMock";
import { Auth0TokenProvider } from "./components/shared/Auth0TokenProvider";
import AxiosInterceptor from "./components/shared/AxiosInterceptor";
import VersionBanner from "./components/shared/VersionBanner";
import { ZuoraIframe } from "./pages/mobile/ZuoraIframe";
import SummaryProvider from "./context/SummaryProvider";
import InformationBanner from "./components/wizard/components/InformationBanner";

const configLoadingErrorMessage =
  "Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)";

const App = () => {
  useAxiosMock();
  const { setConfig, getSiteConfig, config, clientConfig } = useConfig();
  const { isImpersonated, values } = useContext(ImpersonationContext);
  const [redirectUri, setRedirectUri] = useState<string>(
    window.location.origin + "/account"
  );
  const [configLoadingState, setConfigLoadingState] = useState<
    "loading" | "ready" | "error"
  >("loading");
  const [yOffset, setYOffset] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (window.location.pathname === "/csr") {
      if (urlParams.get("consumerId")) {
        setRedirectUri(
          `${window.location.origin}/csr?consumerId=${urlParams.get(
            "consumerId"
          )}`
        );
      } else {
        setRedirectUri(window.location.origin + "/account");
      }
    }
    fetch(dynamicConfigUrl)
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
        setConfigLoadingState("ready");
        axiosInstance.defaults.baseURL = res.apiUrl;
        axiosMemberInstance.defaults.baseURL = res.memberApiUrl;
        axiosMemberCardInstance.defaults.baseURL = res.memberCardApiUrl;
        axiosNetworkInstance.defaults.baseURL = res.networkApiUrl;
        axiosBaseInstance.defaults.baseURL = res.baseApiUrl;
        getSiteConfig();
      })
      .catch((e) => {
        // In Codesandbox.io: deleting `config.json` will not trigger this branch, because the request response code will still be 200, not 404.
        // To test this case in codesanbox.io, add "throw {};" to line 22.

        // In development, treat this case as a warning, render the app and use default config values.
        // In production (and test) on the other hand, show error instead of rendering the app.

        // In Codesandbox.io: You cannot change the value of NODE_ENV. To test this if, change "development"
        if (process.env.NODE_ENV === "development") {
          console.log(
            `Failed to load global configuration from '${dynamicConfigUrl}', using the default configuration instead:`,
            defaultConfig
          );
          setConfigLoadingState("ready");
        } else {
          console.log(
            configLoadingErrorMessage,
            `Have you provided the config file '${dynamicConfigUrl}'?`,
            e
          );
          setConfigLoadingState("error");
        }
      });
  }, [setConfig]);

  useEffect(() => {
    let yOffset = 0;
    if (isImpersonated) yOffset += 60;
    if (config && config.environment !== "prod") yOffset += 40;
    setYOffset(yOffset);
  }, [isImpersonated, config]);

  useEffect(() => {
    document.title = config["title.name"];
  }, [configLoadingState]);

  if (configLoadingState === "loading") {
    return <div></div>; // change to some visual CircularProgress in real app
  }
  if (configLoadingState === "error") {
    return <p className="error-message-text">{configLoadingErrorMessage}</p>;
  }

  if (config["hotjar.hjid"] !== "" && config["hotjar.hjsv"] !== "") {
    hotjar.initialize(
      parseInt(config["hotjar.hjid"], 10),
      parseInt(config["hotjar.hjsv"], 10)
    );
  }
  // maintenance mode
  if (clientConfig.showMaintenancePage) {
    return <Maintenance />;
  }

  return (
    <>
      {config &&
        config.environment !== "prod" &&
        window.location.pathname !== "/iframe" && <VersionBanner />}

      {isImpersonated && (
        <CsrBanner user={values.contactId} agent={values.csrUsername} />
      )}

      <div className="main-layout" style={{ top: `${yOffset}px` }}>
        <ConfigContextObject.Consumer>
          {({ config }) => (
            <Auth0Provider
              domain={config["idp.issuer"]}
              clientId={config["idp.clientId"]}
              authorizationParams={{
                audience: config["idp.audience"],
                impersonatePersonId:
                  new URLSearchParams(window.location.search).get("personId") ||
                  "",
                impersonateClientId:
                  new URLSearchParams(window.location.search).get(
                    "clientCode"
                  ) || "",
                login_hint:
                  window.location.pathname !== "/csr"
                    ? ""
                    : "placeholder@tivityhealth.com",
                redirect_uri: redirectUri,
                "ext-uri": window.location.origin,
              }}
            >
              <Boot>
                <div className="main-layout" style={{ top: `${yOffset}px` }}>
                  <AccountProvider>
                    {config["environment"] !== "prod" &&
                      window.location.pathname !== "/iframe" && <MockPanel />}
                    <ProductBundleProvider>
                      <Router>
                        <InformationBanner />
                        <GeneralRouting />
                        {/* standalone routes */}
                        <Switch>
                          <Route exact path="/csr">
                            <AuthWrapper>
                              <Auth0TokenProvider>
                                <AxiosInterceptor>
                                  <AccountRequiredWrapper>
                                    <Impersonate />
                                  </AccountRequiredWrapper>
                                </AxiosInterceptor>
                              </Auth0TokenProvider>
                            </AuthWrapper>
                          </Route>

                          <Route exact path="/privacy-policy">
                            <Privacy home="/" />
                          </Route>

                          <Route exact path="/terms">
                            <Terms home="/" />
                          </Route>

                          <Route exact path="/fitness-program-agreement">
                            <FitnessProgramAgreement home="/" />
                          </Route>

                          <Route exact path="/locations">
                            <AxiosInterceptor>
                              <Locations />
                            </AxiosInterceptor>
                          </Route>

                          <Route exact path="/success">
                            <Success />
                          </Route>

                          <Route path="/account">
                            <AuthWrapper>
                              <Auth0TokenProvider>
                                <AxiosInterceptor>
                                  <AccountRequiredWrapper>
                                    <SummaryProvider>
                                      <AccountRouting />
                                    </SummaryProvider>
                                  </AccountRequiredWrapper>
                                </AxiosInterceptor>
                              </Auth0TokenProvider>
                            </AuthWrapper>
                          </Route>

                          <Route path="/enroll">
                            <Auth0TokenProvider>
                              <AxiosInterceptor>
                                <Wizard footerOffset={yOffset} />
                              </AxiosInterceptor>
                            </Auth0TokenProvider>
                          </Route>
                          <Route exact path="/sso">
                            <SSO />
                          </Route>
                          <Route exact path="/iframe">
                            <ZuoraIframe />
                          </Route>

                          {/* Dummy route to not render 404 on all pages contained in client routing */}
                          {/* <Route path="*">
                            <NotFound />
                          </Route> */}
                        </Switch>
                      </Router>
                    </ProductBundleProvider>
                  </AccountProvider>
                </div>
              </Boot>
            </Auth0Provider>
          )}
        </ConfigContextObject.Consumer>
      </div>
    </>
  );
};

export default App;
