import { useState, useEffect } from "react";
import { Modal, Button, Card, Form, Spinner } from "react-bootstrap";
import { IAddressFields } from "../../../components/wizard/types";
import { Payment } from "../../../types";
import { ModelValidations } from "../../../components/wizard/model/ModelValidations";
import SmartyStreetsProvider from "../../../context/SmartyStreetsProvider";
import { Formik } from "formik";
import ManagePlanShippingAddress from "../../../components/account/Profile/ManagePlanShippingAddress";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Arrows from "../../../resources/arrows-rotate-solid.svg";
import { useConfig } from "../../../configuration/useConfig";

enum ModalView {
  Default,
  ChangePaymentMethod,
  EditShippingAddress,
}

const NonPayrollCheckoutModal = ({
  show,
  onClose,
  paymentMethod,
  onPaymentChange,
  shippingAddress,
  onAddressEdit,
  total,
  paymentMethods,
  onConfirm,
  setAddressChanged,
}) => {
  const [state, setState] = useState({
    paymentMethodState: paymentMethod,
    agree: false,
    initialValuesAddress: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
    },
    modalView: ModalView.Default,
    verifyOpen: false,
    verifiedAddress: false,
  });
  const { config } = useConfig();
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (shippingAddress !== state.initialValuesAddress) {
      setState({ ...state, initialValuesAddress: shippingAddress });
    }

    if (paymentMethod !== state.paymentMethodState) {
      setState({ ...state, paymentMethodState: paymentMethod });
    }
  }, [shippingAddress, paymentMethod]);

  const handlePaymentChange = () => {
    setState({ ...state, modalView: ModalView.ChangePaymentMethod });
  };

  const handleAddressEdit = () => {
    setState({ ...state, modalView: ModalView.EditShippingAddress });
    if (setAddressChanged) setAddressChanged(true);
  };

  const handlePaymentMethodChange = (newPaymentMethod: Payment) => {
    setState({
      ...state,
      paymentMethodState: newPaymentMethod,
      modalView: ModalView.Default,
    });
    onPaymentChange(newPaymentMethod);
  };

  const handleBack = () => {
    setState({ ...state, modalView: ModalView.Default });
  };

  const handleSubmit = (values: IAddressFields) => {
    if (state.verifiedAddress) {
      setState({ ...state, verifiedAddress: true });
    } else {
      handle(values);
    }
  };

  const handle = async (values: IAddressFields) => {
    try {
      shippingAddress.street1 = values.street1;
      shippingAddress.street2 = values.street2;
      shippingAddress.city = values.city;
      shippingAddress.state = values.state;
      shippingAddress.zip = values.zip;
      handleBack();
    } catch (e) {
      console.log(e);
    }
  };

  const { modalView } = state;

  switch (modalView) {
    case ModalView.ChangePaymentMethod:
      return (
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h1>Select Payment Method</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {paymentMethods?.map((paymentMethod) => (
              <Card
                key={paymentMethod.id}
                onClick={() => handlePaymentMethodChange(paymentMethod)}
                style={{
                  marginBottom: 20,
                  boxShadow: "0 2px 8px #ddd",
                  border: "1px solid rgba(0,0,0,.122)",
                  borderRadius: 5,
                  width: "100%",
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                className="paymentMethodCard"
              >
                <Card.Body>
                  <Card.Text>
                    {paymentMethod.creditCardHolderName}
                    <br />
                    {paymentMethod.creditCardType}{" "}
                    {paymentMethod.creditCardMaskNumber}
                    <br />
                    Exp: {paymentMethod.creditCardExpirationMonth}/
                    {paymentMethod.creditCardExpirationYear}, Zip Code:{" "}
                    {paymentMethod.creditCardPostalCode}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleBack}>
              Back
            </Button>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    //TODO: Implement EditShippingAddress
    case ModalView.EditShippingAddress:
      return (
        <Modal show={show} onHide={onClose} style={{ paddingRight: 10 }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h1>Edit Shipping Address</h1>
            </Modal.Title>
          </Modal.Header>
          <SmartyStreetsProvider>
            <Formik
              enableReinitialize
              initialValues={shippingAddress}
              validationSchema={ModelValidations.AddressFieldsValidation}
              onSubmit={(values) => {
                //TODO - replace with proper handler to submit data and wait for response
                handleSubmit(values);
              }}
            >
              <ManagePlanShippingAddress
                verifyOpen={state.verifyOpen}
                setVerifyOpen={() =>
                  setState({ ...state, verifyOpen: !state.verifyOpen })
                }
                handle={handle}
                setIsVerifiedAddress={() =>
                  setState({ ...state, verifiedAddress: true })
                }
                back={handleBack}
              />
            </Formik>
          </SmartyStreetsProvider>
        </Modal>
      );
    default:
      return (
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h1>Checkout - ${total}</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Payment Method</b>
            <Card
              style={{
                marginBottom: 40,
                boxShadow: "0 2px 8px #ddd",
                border: "1px solid rgba(0,0,0,.122)",
                borderRadius: 5,
                width: "100%",
              }}
            >
              <Card.Body>
                <div className="d-flex align-items-center auth-nopayroll-modal__button-container">
                  <Card.Text>
                    {paymentMethod?.creditCardHolderName}
                    <br />
                    {paymentMethod?.creditCardType}{" "}
                    {paymentMethod?.creditCardMaskNumber}
                    <br />
                    Exp: {paymentMethod?.creditCardExpirationMonth}/
                    {paymentMethod?.creditCardExpirationYear}, Zip Code:{" "}
                    {paymentMethod?.creditCardPostalCode}
                  </Card.Text>
                  <button
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={handlePaymentChange}
                  >
                    <img
                      alt="arrow"
                      src={Arrows}
                      style={{
                        width: 20,
                        height: 20,
                        marginLeft: 10,
                        color: "var(--primary)",
                      }}
                    />
                    &nbsp;
                    <b style={{ color: "var(--primary)" }}>Change</b>
                  </button>
                </div>
              </Card.Body>
            </Card>

            <b>Shipping Address</b>
            <Card
              style={{
                marginBottom: 40,
                boxShadow: "0 2px 8px #ddd",
                border: "1px solid rgba(0,0,0,.122)",
                borderRadius: 5,
                width: "100%",
              }}
            >
              <Card.Body>
                <div className="d-flex align-items-center auth-nopayroll-modal__button-container">
                  <Card.Text>
                    {state.initialValuesAddress.street1} <br />
                    {state.initialValuesAddress.street2 && (
                      <>
                        {state.initialValuesAddress.street2} <br />
                      </>
                    )}
                    {state.initialValuesAddress.city},{" "}
                    {state.initialValuesAddress.state}{" "}
                    {state.initialValuesAddress.zip}
                  </Card.Text>
                  <button onClick={handleAddressEdit}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      style={{ color: "var(--primary)" }}
                    />{" "}
                    <b style={{ color: "var(--primary)" }}>Edit</b>
                  </button>
                </div>
              </Card.Body>
            </Card>

            <b>Agreements</b>
            <div className="d-flex align-items-center auth-nopayroll-modal__button-container">
              <Form.Check
                inline
                name="fitnessProgramAgreementAccepted"
                checked={state.agree}
                onChange={() => setState({ ...state, agree: !state.agree })}
                style={{
                  marginRight: "-15px",
                  marginLeft: "-25px",
                  marginTop: "-20px",
                }}
              />
              <Form.Control.Feedback type="invalid">
                {/* {getIn(errors, "checkout.fitnessProgramAgreement")} */}
              </Form.Control.Feedback>
              <label
                className="form-check-label mb-0"
                htmlFor="flexCheckDefault"
                style={{
                  maxWidth: `100%`,
                  fontSize: `0.9rem`,
                  paddingTop: `10px`,
                }}
              >
                By checking this box, I acknowledge that I have read and agree
                to comply with the terms and conditions of the{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    color: "primary",
                    cursor: "pointer",
                  }}
                  href={
                    config["client"] === "bsca" || config["client"] === "bcbsa"
                      ? `/fitness-program-agreement?clientCode=${config["client"]}`
                      : "/fitness-program-agreement"
                  }
                  target="_blank"
                  className="a-link"
                >
                  Fitness Program Agreement
                </a>
                <span style={{ color: "red" }}>*</span>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Button
              style={{ width: "40%", height: "40%" }}
              className="nav-btn-enroll font-weight-bold"
              variant="outline-primary"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{ width: "50%", height: "40%" }}
              className="nav-btn-enroll font-weight-bold"
              variant="primary"
              disabled={!state.agree || disableButton}
              onClick={() => {
                onConfirm(state.paymentMethodState.id);
                onAddressEdit(shippingAddress);
                setDisableButton(true);
              }}
            >
              {disableButton ? (
                <div className="d-flex justify-content-center">
                  <Spinner
                    animation="border"
                    style={{
                      height: "15px",
                      width: "15px",
                      marginTop: "5px",
                      marginRight: "5px",
                      fontSize: "10px",
                    }}
                  />
                  Paying off balance...
                </div>
              ) : (
                "Confirm & Checkout"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      );
  }
};

export default NonPayrollCheckoutModal;
