import { Fragment, useContext, useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import Landing from "../pages/Landing";
import { ButtonContext } from "../context/ButtonProvider";
import FAQ from "../pages/FAQ";
import useDisableMasking from "../hooks/useDisableMasking";
import Login from "../pages/Login";
import BasicNavHeader from "../components/navigation/BasicNavHeader";
import HowItWorks from "../pages/HowItWorks";
import Locations from "../pages/Locations";

const GeneralRouting = () => {
  const { setButton } = useContext(ButtonContext);
  const [, setOpen] = useState(false);
  const history = useHistory();

  const handleOpen = () => {
    setOpen((open) => !open);
    history.push("/enroll/eligibility");
  };

  useEffect(() => {
    setButton({
      text: "Get Started",
      onClick: handleOpen,
    });
  }, []);

  const handleLogin = () => {
    history.push("/account");
  };

  const location = useLocation();
  useDisableMasking(location);

  return (
    <Fragment>
      <Route exact path="/">
        <BasicNavHeader
          showHome={true}
          includeNavMenu={true}
          handleOpen={handleOpen}
          handleLogin={handleLogin}
        />
        <Landing onOpen={handleOpen} />
      </Route>
      <Route exact path="/how-it-works">
        <BasicNavHeader
          showHome={true}
          includeNavMenu={true}
          handleOpen={handleOpen}
          handleLogin={handleLogin}
        />
        <HowItWorks />
      </Route>
      <Route exact path="/faqs">
        <BasicNavHeader
          showHome={true}
          includeNavMenu={true}
          handleOpen={handleOpen}
          handleLogin={handleLogin}
        />
        <FAQ generalRouting={true} />
      </Route>
      <Route exact path="/locations">
        <BasicNavHeader
          showHome={true}
          includeNavMenu={true}
          handleOpen={handleOpen}
          handleLogin={handleLogin}
        />
        <Locations />
      </Route>
      <Route exact path="/login">
        <BasicNavHeader
          showHome={true}
          includeNavMenu={true}
          handleOpen={handleOpen}
          handleLogin={handleLogin}
        />
        <Login />
      </Route>
    </Fragment>
  );
};

export default GeneralRouting;
