import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const TermedNotEligibleBanner = () => {
  return (
    <Card className="eligibility-toast__card">
      <Card.Body>
        <Row>
          <Col xs={12}>
            <p className="eligibility-toast__title">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span>
                <strong>Attention</strong>
              </span>
            </p>
          </Col>
          <Col xs={12} className="eligibility-toast__body">
            <p>
              Our records indicate that your Walmart Identification Number is no
              longer eligible for Fitness Pass. If you believe this is an error,
              please contact Walmart People Services at 800-421-1362
            </p>
          </Col>
          <Col xs={12}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default TermedNotEligibleBanner;
