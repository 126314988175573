import React, { useEffect, useState } from "react";
import { useConfig } from "../configuration/useConfig";
import useContentful from "../hooks/useContentful";
import { IMaintenancePageFields } from "../@types/generated/contentful";
import { convertToMaintenancePage } from "../utility/contentful";
import { MaintenancePage } from "../types";

const Maintenance = () => {
  const { config } = useConfig();
  const { getMaintenancePage } = useContentful(config);
  const [maintenanceContent, setMaintenanceContent] =
    useState<IMaintenancePageFields | null>(null);
  const [maintenanceVerbiage, setMaintenanceVerbiage] =
    useState<MaintenancePage | null>(null);

  useEffect(() => {
    (async () => {
      getMaintenancePage().then((res) => {
        if (res) {
          setMaintenanceContent(res.items[0].fields);
          setMaintenanceVerbiage(
            convertToMaintenancePage(
              res.items[0].fields as unknown as IMaintenancePageFields
            )
          );
        }
      });
    })();
    // eslint-disable-next-line
  }, []);
  if (!maintenanceContent) {
    return null;
  }

  return (
    <div className="d-flex flex-column m-3">
      <div className="d-flex justify-content-center m-2">
        <div className="d-flex align-items-center w-100 flex-column">
          <img
            src={maintenanceContent.logo.fields.file.url}
            alt="Logo"
            width={`20%`}
          />
          <img
            src={maintenanceContent.image.fields.file.url}
            alt="Maintenance"
            className="m-1"
            width={`30%`}
          />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center text-center">
        {maintenanceVerbiage !== null && maintenanceVerbiage.body.content}
      </div>
    </div>
  );
};

export default Maintenance;
