import React, { useState, useEffect } from "react";
import { IBannerFields } from "../../../@types/generated/contentful";
import { tags } from "../../../utility/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Entry } from "contentful";
import { useConfig } from "../../../configuration/useConfig";
import useContentful from "../../../hooks/useContentful";
import { useLocation } from "react-router-dom";

const InformationBanner = () => {
  const config = useConfig();
  const { getBanner } = useContentful(config.config);
  const [allPages, setAllPages] = useState(false);
  const [display, setDisplay] = useState(false);
  const [itemTag, setItemTag] = useState("");
  const [show, setShow] = useState(false);
  const [content, setContent] = useState<Entry<IBannerFields> | null>(null);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      getBanner().then((res) => {
        if (res) {
          // Clients will always have a single banner, except for BCBSA and BSCA, which could have one for each client, or a shared one. If there is more than one banner, get the content based on the tag for each client.
          res.items.forEach((item) => {
            if (item.fields.displayBanner && !item.fields.isClientSpecific) {
              setContent(item);
              setAllPages(item.fields.displayAllPages);
              setDisplay(item.fields.displayBanner);
            }

            if (item.fields.displayBanner && item.fields.isClientSpecific) {
              const tagId = item.metadata.tags[0]?.sys.id;
              if (tagId === tags[config.config["client"]]) {
                setItemTag(tagId);
                setContent(item);
                setAllPages(item.fields.displayAllPages);
                setDisplay(item.fields.displayBanner);
              }
            }
          });
        }
      });
    })();
  }, []);

  useEffect(() => {
    if (display) {
      if (itemTag !== "" && itemTag !== tags[config.config["client"]]) {
        setShow(false);
        return;
      }
      if (
        (!allPages && location.pathname === "/") ||
        (!allPages && location.pathname === "/enroll/eligibility") ||
        (!allPages && location.pathname === "/how-it-works") ||
        (!allPages && location.pathname === "/faqs") ||
        (!allPages && location.pathname === "/terms") ||
        (!allPages && location.pathname === "/privacy-policy") ||
        (!allPages && location.pathname === "/fitness-program-agreement")
      ) {
        setShow(false);
      } else {
        setShow(true);
      }
    } else {
      setShow(false);
    }
  }, [location, display, allPages, itemTag]);

  return (
    <>
      {content && show && (
        <div className="informationBanner">
          {content?.fields.bannerText &&
            documentToReactComponents(content.fields.bannerText)}
        </div>
      )}
    </>
  );
};

export default InformationBanner;
