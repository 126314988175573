import { Fragment } from "react";
import { useFormikContext } from "formik";
import Header from "../components/Header";
import PackageCard from "../components/PackageCard";
import { EligibilityFlow, FormikWizardState } from "../types";
import Locations from "../components/Locations";
import { ErrorBoundary } from "react-error-boundary";
import { useState } from "react";
import StatusModal from "../components/StatusModal";
import FooterButtons from "../components/FooterButtons";
import SomethingWentWrong from "../../../pages/SomethingWentWrong";
import { useContext } from "react";
import { AccountContext } from "../../../context/AccountProvider";
import { useEffect } from "react";
import { ProductBundleContext } from "../../../context/ProductBundleProvider";
import { Spinner, Modal } from "react-bootstrap";
import useGetPrograms from "../../../hooks/useGetPrograms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
import { useConfig } from "../../../configuration/useConfig";
import BinaryToggle from "../../shared/BinaryToggle/BinaryToggle";
import CustomContentfulModal from "../../modal/CustomContentfulModal";
import StudioPackageCard from "../components/StudioPackageCard";
import {
  PROGRAM_CODE,
  USER_PAYROLL_FREQUENCY,
  USER_PAYROLL_STATUS,
} from "../../../context/UserProvider";

export type Product = {
  id: string;
  billingPeriod: string;
  credits: number;
  description: string;
  isBestValue: boolean;
  name: string;
  recurringPrice: number;
  recurringRatePlanChargeId: string;
  setupFee: number;
  setupRatePlanChargeId: string;
  tier: number;
};

const Package = ({ next }: { next?: () => void }) => {
  const { values, errors, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<FormikWizardState<EligibilityFlow>>();
  const { loading: loadingBundles } = useContext(ProductBundleContext);
  const [show, setShow] = useState(false);
  const [luxuryModalOpen, setLuxuryModalOpen] = useState(false);
  const [primeProductRatePlans, setPrimeProductRatePlans] = useState<Product[]>(
    []
  );
  const [programCode, setProgramCode] = useState("");
  const [openMap, setOpenMap] = useState(false);
  const [
    isIndividualStudioPurchaseAllowed,
    setIsIndividualStudioPurchaseAllowed,
  ] = useState<boolean>(false);
  const [isHomeGymSelectionAllowed, setIsHomeGymSelectionAllowed] =
    useState<boolean>(false);
  const [selectedToggle, setSelectedToggle] = useState<string>("");

  const { member, acceptedTerms, clientPayrollFrequency, clientPayrollStatus } =
    useContext(AccountContext);
  const [setupFee, setSetupFee] = useState<number>(0);
  const { isSmallScreen } = useGetScreenSize();
  const { clientConfig, config } = useConfig();

  const { error, loading, productRatePlans } = useGetPrograms(
    true,
    member?.personId && member.eligibilities[0].isPrimary
      ? clientPayrollStatus === USER_PAYROLL_STATUS.Payroll
      : values.eligibility?.isPayroll,
    member?.personId && member.eligibilities[0].isPrimary
      ? (clientPayrollFrequency as USER_PAYROLL_FREQUENCY)
      : (values.eligibility?.payrollFrequency as USER_PAYROLL_FREQUENCY)
  );

  const faMapIcon = faMap as IconProp;

  const logError = (error: Error) => {
    console.log({ error });
  };

  const toggleOptions = [
    { name: "Classic Gyms", value: "classic" },
    { name: "Luxury Gyms", value: "luxury" },
  ];

  useEffect(() => {
    setSelectedToggle(toggleOptions[0].value);
  }, []);

  useEffect(() => {
    if (primeProductRatePlans) {
      if (selectedToggle === "classic") {
        setFieldValue("package.tier", 3);
      } else {
        setFieldValue("package.tier", primeProductRatePlans.length - 1);
      }
    }
  }, [selectedToggle]);

  useEffect(() => {
    if (isSmallScreen) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (config["client"] === "walmart") {
      if (values.eligibility?.payrollStatus) {
        if (
          values.eligibility?.payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly
        )
          setProgramCode(PROGRAM_CODE.WalmartPayrollWeekly);
        else setProgramCode(PROGRAM_CODE.WalmartPayrollBiWeekly);
      } else {
        setProgramCode(PROGRAM_CODE.PrimeLTE);
      }
    }
    if (config["client"] === "hcsc") {
      setProgramCode(PROGRAM_CODE.PrimeSTD);
    }
    if (config["client"] === "bsca" || config["client"] === "bcbsa") {
      setProgramCode(PROGRAM_CODE.PrimeFYW);
    }
    if (config["client"] === "mutual-of-omaha") {
      setProgramCode(PROGRAM_CODE.PrimeLGY);
    }
  }, []);

  useEffect(() => {
    setIsIndividualStudioPurchaseAllowed(
      !!clientConfig.isIndStudioPurchaseAllowed
    );
    setIsHomeGymSelectionAllowed(!!clientConfig.isHomeGymSelectionAllowed);
  }, [clientConfig]);

  useEffect(() => {
    if (!loading && !error) {
      setPrimeProductRatePlans(productRatePlans);
      const maxValue = Math.max.apply(
        null,
        productRatePlans.map(function (o) {
          return o.setupFee;
        })
      );
      setSetupFee(maxValue);
    }
  }, [programCode, loading]);

  useEffect(() => {
    if (member?.personId && member.eligibilities[0].isPrimary) {
      const {
        firstName,
        lastName,
        dateOfBirth,
        email,
        eligibilities,
        postalCode,
        consumerId,
      } = member;
      const dob = new Date(dateOfBirth || 0);
      const year = dob.getFullYear();
      const month = ("0" + (dob.getMonth() + 1)).slice(-2).toString();
      const day = ("0" + dob.getDate()).slice(-2).toString();
      setFieldValue("eligibility.firstName", firstName);
      setFieldValue("eligibility.lastName", lastName);
      setFieldValue(
        "eligibility.isPayroll",
        clientPayrollStatus === USER_PAYROLL_STATUS.Payroll
      );
      setFieldValue("eligibility.payrollFrequency", clientPayrollFrequency);
      setFieldValue("eligibility.year", year);
      setFieldValue("eligibility.day", day);
      setFieldValue("eligibility.month", month);
      setFieldValue("eligibility.privacyPolicy", acceptedTerms);
      setFieldValue(
        "package.sku",
        eligibilities[0]?.currentSubscription?.ratePlanName
      );
      setFieldValue("eligibility.consumerId", consumerId);
      setFieldValue(
        "eligibility.associateIdentificationNumber",
        eligibilities[0]?.clientMemberId
      );
      setFieldValue(
        "eligibility.clientMemberId",
        eligibilities[0].clientMemberId
      );
      setFieldValue("eligibility.email", email);
      setFieldValue(
        "eligibility.subscriberId",
        config["client"] === "walmart"
          ? eligibilities[0]?.clientMemberId
          : eligibilities[0]?.subscriberId
      );
      setFieldValue("eligibility.zip", postalCode);
      setFieldValue("package.isReenrolled", false);
      setFieldValue("package.isCancelled", false);
      if (member.eligibilities[0].termReason === "RS50") {
        setFieldValue("eligibility.isPayroll", false);
      }
      if (member.eligibilities[0].currentSubscriptionStatus === "Cancelled") {
        setFieldValue("package.isCancelled", true);
      }
    }
  }, [member]);

  useEffect(() => {
    setFieldTouched("package");
  }, [values]);

  const handleSubmit = () => {
    const isStudio = values.package?.sku === PROGRAM_CODE.Studio;
    const plan = primeProductRatePlans.filter(
      (plan) => plan.name === values.package?.sku
    );
    setFieldValue("package.sku", values.package?.sku);
    setFieldValue(
      "package.ratePlanChargeId",
      plan[0].recurringRatePlanChargeId
    );
    setFieldValue("package.completed", true);
    setFieldValue(
      "package.programCode",
      isStudio ? PROGRAM_CODE.Studio : programCode
    );
    setFieldValue("package.tier", plan[0].tier);
    if (next) {
      next();
    }
  };

  useEffect(() => {
    if (
      member?.eligibilities[0]?.productCode === "261" ||
      member?.eligibilities[0]?.productCode === "260" ||
      member?.eligibilities[0]?.productCode === "226"
    ) {
      setFieldValue("eligibility.completed", true);
    }
  }, []);

  const updatePackageSelectedTier = (product: Product) => {
    setFieldValue("package.tier", product.tier);
  };

  const handleCancel = () => {
    setShow(false);
  };

  const filterClassic = (product: Product) => {
    return product.tier < 4 && product.tier > 0;
  };

  const filterLuxury = (product: Product) => {
    return product.tier >= 4;
  };
  return (
    <>
      <Fragment>
        <StatusModal
          show={show}
          status={"ERROR"}
          message=""
          onCancel={handleCancel}
          onNext={() => {
            return;
          }}
        />
        <div className="package-step-container row">
          <div className="package-selection-container col">
            <Header
              title="What fitness package are you interested in?"
              subtitle={
                !isSmallScreen ? "Please confirm your selection below." : ""
              }
              enrollmentFee={
                setupFee > 0
                  ? `There is a one time fee of $${setupFee} for gym packages only`
                  : ``
              }
            />
            {isSmallScreen && (
              <span style={{ marginBottom: "10px" }}>
                <a
                  style={{
                    cursor: "pointer",
                    color: "#0071CE",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setOpenMap(true);
                  }}
                >
                  {" "}
                  <span>
                    <FontAwesomeIcon icon={faMapIcon} />
                  </span>{" "}
                  View Map
                </a>
              </span>
            )}
            {isSmallScreen && (
              <div className="package-card-container">
                <h6
                  className="luxury-gym-info"
                  onClick={() => setLuxuryModalOpen(!luxuryModalOpen)}
                >
                  What is a Luxury Gym?
                </h6>
              </div>
            )}
            {clientConfig.isHomeGymSelectionAllowed && (
              <div
                className="package-card-container"
                style={{
                  display: `flex`,
                  flexDirection: "row",
                  justifyContent: `space-between`,
                  // maxWidth: "300px",
                }}
              >
                <BinaryToggle
                  options={toggleOptions}
                  selectedToggle={selectedToggle}
                  setSelectedToggle={setSelectedToggle}
                />
                {!isSmallScreen && (
                  <h6
                    className="luxury-gym-info"
                    onClick={() => setLuxuryModalOpen(!luxuryModalOpen)}
                  >
                    What is a Luxury Gym?
                  </h6>
                )}
              </div>
            )}

            {loading ? (
              <div className="d-flex justify-content-center align-items-center m-2">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="package-card-container row">
                {primeProductRatePlans
                  .filter(
                    selectedToggle === "luxury" ? filterLuxury : filterClassic
                  )
                  .map((item) => {
                    if (item.tier !== 0) {
                      return (
                        <div
                          className={`${item.name} col mb-4`}
                          key={item.name}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <PackageCard
                              productInfo={item}
                              price={item.recurringPrice ?? 0}
                              handleChange={handleChange}
                              updateTier={updatePackageSelectedTier}
                              selected={values.package!.sku}
                              loading={loadingBundles}
                              name={item.name}
                              className={item.name}
                              isPayroll={!!values.eligibility?.isPayroll}
                              payrollFrequency={
                                values.eligibility
                                  ?.payrollFrequency as USER_PAYROLL_FREQUENCY
                              }
                              isIndividualStudioPurchaseAllowed={
                                isIndividualStudioPurchaseAllowed
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                {primeProductRatePlans.map((item) => {
                  if (
                    selectedToggle === "classic" &&
                    item.tier === 0 &&
                    item.name !== "studio"
                  ) {
                    return (
                      <div
                        className={`${item.name} ${
                          isSmallScreen ? "col mb-4" : "row"
                        }`}
                        key={item.name}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <PackageCard
                            productInfo={item}
                            price={item.recurringPrice ?? 0}
                            handleChange={handleChange}
                            updateTier={updatePackageSelectedTier}
                            selected={values.package!.sku}
                            loading={loadingBundles}
                            name={item.name}
                            className={item.name}
                            isPayroll={!!values.eligibility?.isPayroll}
                            payrollFrequency={
                              values.eligibility
                                ?.payrollFrequency as USER_PAYROLL_FREQUENCY
                            }
                            isIndividualStudioPurchaseAllowed={
                              isIndividualStudioPurchaseAllowed
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                })}
                {selectedToggle === "classic" &&
                  isIndividualStudioPurchaseAllowed && (
                    <div
                      className={`${"Digital"} ${
                        isSmallScreen ? "col mb-4" : "row"
                      }`}
                      key={"studio"}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <StudioPackageCard
                          productInfo={primeProductRatePlans.find(
                            (prp) => prp.name === "studio"
                          )}
                          price={0}
                          handleChange={handleChange}
                          updateTier={updatePackageSelectedTier}
                          selected={values.package!.sku}
                          loading={loadingBundles}
                          name={"studio"}
                          className={"Digital"}
                          isPayroll={!!values.eligibility?.isPayroll}
                          isIndividualStudioPurchaseAllowed={
                            isIndividualStudioPurchaseAllowed
                          }
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
          <div
            className="map-container col-4 justify-content-center align-items-center d-none d-lg-flex"
            style={{}}
          >
            <ErrorBoundary
              FallbackComponent={SomethingWentWrong}
              onError={logError}
            >
              <>
                <Locations
                  packageSelected={
                    values.package?.tier === undefined
                      ? "3"
                      : (values.package?.tier as string).toString() ?? "3"
                  }
                  isIndividualStudioPurchaseAllowed={
                    isIndividualStudioPurchaseAllowed
                  }
                  isHomeGymSelectionAllowed={isHomeGymSelectionAllowed}
                />
              </>
            </ErrorBoundary>
          </div>
        </div>
        <FooterButtons
          onSubmit={handleSubmit}
          submitDisabled={errors.package !== undefined}
          submitText={"Save & Proceed"}
          packageStyling={true}
        />
      </Fragment>
      {luxuryModalOpen && (
        <CustomContentfulModal
          show={luxuryModalOpen}
          onClose={() => setLuxuryModalOpen(!luxuryModalOpen)}
        />
      )}
      {openMap && (
        <>
          <Modal
            show={openMap}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-100w"
            style={{ height: "100vh" }}
          >
            <Modal.Header>
              <Modal.Title>Location Search</Modal.Title>
              <span
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setOpenMap(false);
                }}
              >
                X
              </span>
            </Modal.Header>

            <Modal.Body
              style={{ marginTop: "-25px", height: "calc(100vh - 56px)" }}
            >
              <Locations
                packageSelected={
                  values.package?.tier === undefined
                    ? "3"
                    : (values.package?.tier as string).toString() ?? "3"
                }
                isIndividualStudioPurchaseAllowed={
                  isIndividualStudioPurchaseAllowed
                }
                isHomeGymSelectionAllowed={isHomeGymSelectionAllowed}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Package;
