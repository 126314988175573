import { ConfigResponse } from "../utility/member-portal-api-types";

export interface DynamicConfig {
  version: string;
  apiUrl: string;
  client: string;
  baseApiUrl: string;
  memberApiUrl: string;
  memberCardApiUrl: string;
  networkApiUrl: string;
  apiUrlNew: string;
  contentfulSpace: string;
  contentfulAccessToken: string;
  contentfulPreviewAccessToken: string;
  contentfulEnvironment: string;
  environment: string;
  "contact.phoneNumber": string;
  "google.maps.key": string;
  "idp.clientId": string;
  "idp.issuer": string;
  "idp.audience": string;
  "burnalong.clientId": string;
  "site.baseUrl": string;
  "smartystreets.key": string;
  "zuora.hostedPagePath": string;
  "zuora.paymentGatewayCode.cc": string;
  "zuora.paymentGatewayCode.echeck": string;
  "hotjar.hjid": string;
  "hotjar.hjsv": string;
  "primeOnDemand.enable": string;
  "title.name": string;
  "help.email": string;
  programName: string;
  logo: string;
  auth0ConnectionName: string;
}

export const defaultConfig: DynamicConfig = {
  apiUrl: "http://localhost:3005/boot.json",
  client: "",
  baseApiUrl: "",
  memberApiUrl: "",
  memberCardApiUrl: "",
  networkApiUrl: "",
  apiUrlNew: "",
  environment: "DEV",
  version: "",
  contentfulSpace: "walmart-uat",
  contentfulAccessToken: "",
  contentfulPreviewAccessToken: "",
  contentfulEnvironment: "",
  "contact.phoneNumber": "",
  "google.maps.key": "",
  "idp.clientId": "",
  "idp.issuer": "",
  "idp.audience": "",
  "burnalong.clientId": "",
  "site.baseUrl": "",
  "smartystreets.key": "",
  "zuora.hostedPagePath": "",
  "zuora.paymentGatewayCode.cc": "",
  "zuora.paymentGatewayCode.echeck": "",
  "hotjar.hjsv": "",
  "hotjar.hjid": "",
  "primeOnDemand.enable": "false",
  "title.name": "",
  "help.email": "",
  programName: "",
  logo: "",
  auth0ConnectionName:"",
};

export const defaultClientConfig: ConfigResponse = {
  subClientID: 1,
  clientID: 1,
  clientName: null,
  costCenterName: null,
  costCenterCode: null,
  productCode: null,
  productFullName: null,
  subClientName: null,
  incomingHealthPlanUrl: null,
  eligibilityInputType: null,
  healthPlanAPIKey: null,
  healthPlanAPIClientID: null,
  healthPlanAPIClientSecret: null,
  healthPlanAPIUserName: null,
  healthPlanAPIPassword: null,
  healthPlanAPICertPassword: null,
  isWholeHealthAllowed: null,
  isFamilyMembershipAllowed: null,
  isIndStudioPurchaseAllowed: null,
  studioDisplayType: null,
  isGymsAllowed: null,
  isFlexAllowed: null,
  isWearablesAllowed: null,
  isVirtualTrainingAllowed: null,
  isGymNominationAllowed: null,
  isHomeGymSelectionAllowed: null,
  isLegacyMigrationOn1stVisitAllowed: null,
  isManualCheckInAllowed: null,
  terminationRule: null,
  isIMETsAllowed: null,
  minFamilyMemberAge: null,
  minBuyerMemberAge: null,
  minConsumerNonBuyerAge: null,
  setPrimaryByClient: null,
  setPrimaryByUser: null,
  isDependentAccessLinked: null,
  dependentSource: null,
  termsOfUpp: null,
  programAgreement: null,
  dependentsMaxNum: null,
  isIdpEnabled: null,
  allowFamilyMemberManualAdd: null,
  allowMemberSelfCancellation: null,
  isDefaultProduct: null,
  requiredForEligibilityCheck: null,
  clientFieldMappings: null,
  subClientGenConfigs: null,
  rewardableActivity: null,
  idpRolesAssigned: null,
  clientConsumerMappings: null,
  ssoFieldEligMappings: null,
  communicationTypes: null,
  memberCancellations: null,
  nonpayrollStates: null,
  mockClientCalls:  null,
  showMaintenancePage: null,
  maskCallRecording: null,
  reenrollmentRule: null,
  isPromoCodeAllowed:  null,
  isMobileApplicationAllowed:  null,
  clientSpecificConfig: null,
};

export const dynamicConfigUrl = "/boot.json";
